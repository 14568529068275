import React from 'react'

import { COLORS, CloudinaryImage } from 'ethos-design-system'
import { cn } from 'lib/client-utils'

import PriorityImage from '@/components/global/PriorityImage'

import { usePaidLandersUpdate } from '@/hooks/features/usePaidLandersUpdate'

import PressWreaths from '../../common/PressWreaths'
import { Subheading } from '../../common/Subheading'
import styles from './ForegroundImage.module.scss'
import { H1Responsive } from './H1Responsive'
import { LayoutProps } from './index'

const PRESS_WREATHS = [
  {
    title: '#1',
    subtitle: 'Instant \n life insurance',
    logoUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1715196289/Forbes_mzdx7a.svg',
    logoAlt: 'Forbes',
  },
  {
    title: 'Best',
    subtitle: 'No-exam \n term life policy',
    logoUrl:
      'https://res.cloudinary.com/getethos/image/upload/v1715196289/BI_oyssc5.svg',
    logoAlt: 'Business Insider',
  },
]

const ForegroundImage = ({
  updateScrollNode,
  moduleData,
  formManager,
}: LayoutProps) => {
  const { isTreatment1: isPaidLandersTreatment1 } = usePaidLandersUpdate()

  const MD = moduleData
  let formClasses = 'ForegroundImageForm w-100 mx-auto md:mx-0 pb-12'

  if (MD.groupImage && MD.imageAlt) {
    if (MD.displayTrust && !MD.displayEstimatedCredit) {
      formClasses += ' md:mb-32 lg:mb-6'
    } else if (!MD.displayEstimatedCredit) formClasses += ' md:mb-32 lg:mb-24'
  }

  const trustClasses =
    'mx-auto md:mx-0 pt-4 pb-6 w-48 flex justify-between items-center'

  const socialProof = (
    <>
      <CloudinaryImage
        lazyLoad={true}
        publicId={
          'https://res.cloudinary.com/getethos/image/upload/v1678400098/00_CMS/google4-7-stars_hsdbzc.png'
        }
        alt={'Google Review Stars'}
        crop={CloudinaryImage.CROP_METHODS.FIT}
        height={[55, 55, 55, 55]}
        width={[90, 90, 90, 90]}
      />
      <CloudinaryImage
        lazyLoad={true}
        publicId={
          'https://res.cloudinary.com/getethos/image/upload/v1685748009/TrustPilotColor_esa1pl.png'
        }
        alt={'TrustPilot Review Stars'}
        crop={CloudinaryImage.CROP_METHODS.FIT}
        height={[55, 55, 55, 55]}
        width={[90, 90, 90, 90]}
      />
    </>
  )

  const sectionBgColor = `bg-${MD.backgroundColor}`
  const imageHeightArray = [250, 250, 325, 400]
  const imageWidthArray = [250, 250, 325, 400]

  const section = `floatingContainer ForegroundImage relative w-full ${sectionBgColor}`

  const container =
    'relative w-full max-w-screen-xl relative flex flex-col flex-wrap md:flex-row md:flex-nowrap md:justify-between mx-auto px-3 pt-8 md:px-12 lg:justify-around lg:pt-20 lg:pb-0 lg:px-16'

  const textAndImage =
    'relative text-center flex flex-col md:w-1/2 md:pr-6 md:text-left md:flex-grow lg:w-100 lg:pr-12 overflow-hidden'

  const heading = 'pb-2 md:pb-6'

  const subHeadingAndImage = 'flex flex-col lg:flex-row justify-between '
  const subHeading =
    'z-1 max-w-xs lg:pb-2 text-center mx-auto md:mx-0 md:pb-0 md:max-w-none md:text-left lg:w-1/2'

  const image =
    'ForegroundImagePicture hidden absolute z-0 left-0 right-12 bottom-0 h-64 lg:block md:h-auto md:left-auto'
  const imageClasses = [styles.foregroundImage]
  if (MD.displayTrust) {
    imageClasses.push(styles.trust)
  }

  const renderTrustSignals = () => {
    if (isPaidLandersTreatment1) {
      return (
        <div className="z-1 mb-6 flex justify-center gap-x-6 gap-y-6 md:items-center md:justify-start lg:items-start lg:max-[1080px]:flex-col">
          {PRESS_WREATHS.map((wreath, index) => (
            <div
              className={cn(index === 0 && 'lg:max-[1080px]:order-1')}
              key={wreath.logoUrl}
            >
              <PressWreaths
                title={wreath.title}
                subtitle={wreath.subtitle}
                logoUrl={wreath.logoUrl}
                logoAlt={wreath.logoAlt}
                variant="dark"
              />
            </div>
          ))}
        </div>
      )
    }

    if (MD.displayTrust) {
      return <div className={trustClasses}>{socialProof}</div>
    }
  }

  return (
    <section className={section} ref={updateScrollNode}>
      <div className={container}>
        <div className={textAndImage}>
          <H1Responsive
            className={heading}
            color={COLORS.BRAND_FOREST}
            copy={moduleData.heading}
          />
          <div className={subHeadingAndImage}>
            <Subheading
              className={subHeading}
              color={COLORS.BRAND_FOREST}
              displaySubHeading={MD.displaySubHeading}
            >
              {moduleData.subHeading}
            </Subheading>
            {MD.groupImage && MD.imageAlt && (
              <div className={image}>
                <PriorityImage
                  className={imageClasses.join(' ')}
                  publicId={MD.groupImage}
                  alt={MD.imageAlt}
                  crop={CloudinaryImage.CROP_METHODS.FIT}
                  height={imageHeightArray}
                  width={imageWidthArray}
                />
              </div>
            )}
          </div>
          {renderTrustSignals()}
        </div>
        {formManager && (
          <div
            className={cn(formClasses, isPaidLandersTreatment1 && 'md:mb-0')}
          >
            {formManager}
          </div>
        )}
      </div>
    </section>
  )
}

export default ForegroundImage
