import { useEffect } from 'react'

import dynamic from 'next/dynamic'

import Cookies from 'js-cookie'
import { ASTA, DAILY_DOLLARS_COOKIE } from 'lib/@getethos/experiments/constants'
import { PAID_LANDERS_UPDATE } from 'lib/@getethos/experiments/feature_constants'

import EstimateWidget from '@/components/EstimateWidget/EstimateWidget'
import FaqTabs from '@/components/Faq/FaqTabs'
import SingleCTA from '@/components/SingleCTA/SingleCTA'

import { usePaidLandersUpdate } from '@/hooks/features/usePaidLandersUpdate'
import { useUpdatedCpFeLp } from '@/hooks/features/useUpdatedCpFeLp'
import { useWillsLPExperiment } from '@/hooks/features/useWillsLPExperiment'
import { useWillsLPV2_2Experiment } from '@/hooks/features/useWillsLPExperimentV2_2'

import { useShouldHideModule } from './hooks'

const moduleComponents = {
  AccordionFaq: dynamic(
    () => import('@/components/AccordionFaq/AccordionFaqWrapper')
  ),
  AgentLead: dynamic(() => import('@/components/AgentLead/AgentLead')),
  AgentResourcesTabs: dynamic(
    () => import('@/components/AgentResources/AgentResourcesTabs')
  ),
  BannerImage: dynamic(() => import('@/components/Banner/BannerImage')),
  BannerLogos: dynamic(() => import('@/components/Banner/BannerLogosWrapper')),
  BannerText: dynamic(() => import('@/components/Banner/BannerText')),
  BBBCard: dynamic(() => import('@/components/BestBusinessBureau/BBBCard')),
  BlendedTextImage: dynamic(
    () => import('@/components/BlendedTextImage/BlendedTextImage')
  ),
  BlogImage: dynamic(() => import('@/components/Blog/BlogImage')),
  BlogImageSplit: dynamic(() => import('@/components/Blog/BlogImageSplit')),
  BlogStory: dynamic(() => import('@/components/Blog/BlogStory')),
  Cards: dynamic(() => import('@/components/Cards/Cards')),
  ComparisonChartProsCons: dynamic(
    () => import('@/components/ComparisonChart/ComparisonChartProsCons')
  ),
  ComparisonChartYesNo: dynamic(
    () => import('@/components/ComparisonChart/ComparisonChartYesNo')
  ),
  ComparisonChartYesNoWills: dynamic(
    () => import('@/components/ComparisonChart/ComparisonChartYesNoWills')
  ),
  TwoColumnComparisonChart: dynamic(
    () => import('@/components/ComparisonChart/TwoColumnComparisonChart')
  ),
  CtaIconColumns: dynamic(
    () => import('@/components/CtaIconColumns/CtaIconColumns')
  ),
  EmailCapture: dynamic(() => import('@/components/EmailCapture/EmailCapture')),
  EstimateExperience: dynamic(() => import('@/components/EstimateExperience')),
  EstimateWidget: EstimateWidget,
  FaqTabs: FaqTabs,
  Greenhouse: dynamic(() => import('@/components/Greenhouse/Greenhouse')),
  BlendedHeader: dynamic(() => import('@/components/Headers/BlendedHeader')),
  HeaderHome: dynamic(() => import('@/components/Headers/HeaderHome')), // not used anywhere
  HeaderSimple: dynamic(() => import('@/components/Headers/HeaderSimple')), // not used anywhere
  HeaderVersatile: dynamic(
    () => import('@/components/Headers/HeaderVersatile')
  ),
  IntentHeader: dynamic(() => import('@/components/Headers/IntentHeader')),
  IntentHeaderAgent: dynamic(
    () => import('@/components/Headers/IntentHeaderAgent')
  ),
  IntentHeaderBalanced: dynamic(
    () => import('@/components/Headers/IntentHeaderBalanced')
  ),
  Highlight: dynamic(() => import('@/components/Highlight/HighlightWrapper')),
  Sitemap: dynamic(() => import('@/components/Sitemap/Sitemap')),
  HighlightNumbers: dynamic(
    () => import('@/components/HighlightNumbers/HighlightNumbers')
  ),
  Licenses: dynamic(() => import('@/components/Licenses/Licenses')), //
  LogoSummary: dynamic(() => import('@/components/LogoSummary/LogoSummary')),
  MultiColumn: dynamic(() => import('@/components/MultiColumn/MultiColumn')),
  MultiLogoTextImage: dynamic(
    () => import('@/components/MultiLogoTextImage/MultiLogoTextImage')
  ),
  NavigableContent: dynamic(
    () => import('@/components/NavigableContent/NavigableContent')
  ),
  SearchIndex: dynamic(() => import('@/components/Search/SearchIndex')),
  SimpleColumns: dynamic(
    () => import('@/components/SimpleColumns/SimpleColumns')
  ),
  SingleCTA: SingleCTA,
  SocialProofBanner: dynamic(
    () => import('@/components/SocialProofBanner/SocialProofBanner') // not used anywhere
  ),
  SpacerBlock: dynamic(() => import('@/components/SpacerBlock/SpacerBlock')),
  StarTestimonial: dynamic(
    () => import('@/components/StarTestimonial/StarTestimonial')
  ),
  Story: dynamic(() => import('@/components/Story/Story')),
  SupportedImage: dynamic(
    () => import('@/components/SupportedImage/SupportedImage')
  ),
  Testimonials: dynamic(() => import('@/components/Testimonials/Testimonials')),
  ThreeBoxes: dynamic(() => import('@/components/ThreeBoxes/ThreeBoxes')),
  TrustNumbers: dynamic(() => import('@/components/TrustNumbers/TrustNumbers')),
  Video: dynamic(() => import('@/components/Video/Video')),
  ValueProps: dynamic(() => import('@/components/ValueProps/ValueProps')),
  HighlightSlider: dynamic(
    () => import('@/components/HighlightSlider/HighlightSlider')
  ),
  TestimonialSlider: dynamic(
    () => import('@/components/TestimonialSlider/TestimonialSliderWrapper')
  ),
  RatingImages: dynamic(() => import('@/components/RatingImages/RatingImages')),
  PlanBenefits: dynamic(() => import('@/components/PlanBenefits/PlanBenefits')),
  HeaderSemiversatile: dynamic(
    () =>
      import(
        '@/components/Headers/HeaderSemiversatile/HeaderSemiVersatileWrapper'
      )
  ),
  TrustPilot: dynamic(
    () => import('@/components/TrustPilot/TrustPilotWrapper')
  ),
  TrustPilotBanner: dynamic(
    () =>
      import('@/components/TrustPilot/TrustPilotBanner/TrustPilotBannerWrapper')
  ),
  AuthorBio: dynamic(() => import('@/components/AuthorBio/AuthorBioWrapper')),
  PartnerSignup: dynamic(
    () => import('@/components/PartnerSignup/PartnerSignupWrapper')
  ),
  BlogPreview: dynamic(
    () => import('@/components/Blog/BlogPreview/BlogPreview')
  ),
  Checkerboard: dynamic(() => import('@/components/Checkerboard/Checkerboard')),
  ToMainApp: dynamic(() => import('@/components/ToMainApp/ToMainApp')),
  WillsHold: dynamic(() => import('@/components/Wills/WillsHold')),
  WillsPlans: dynamic(() => import('@/components/Wills/WillsPlans/WillsPlans')),
  TestimonialFull: dynamic(
    () => import('@/components/TestimonialFull/TestimonialFull')
  ),
  Badges: dynamic(() => import('@/components/Badges/Badges')),
}

const DynamicClientComponent = ({
  tag,
  moduleData,
  settings,
  isAstaLayout = false,
  ...rest
}: any) => {
  const Component = moduleComponents[
    tag as keyof typeof moduleComponents
  ] as any
  const className = 'DynamicComponent DynamicComponent--' + [tag]
  const expKey = moduleData?.experimentKey
  const currentPath =
    typeof window !== 'undefined' ? window.location.pathname : null

  // --------------------- Start: Final Expense Landing Page Cookie ---------------------
  // TODO refactor into an admin setting
  const finalExpensePath = '/life/final-expense/'
  const setCookieForFE = finalExpensePath === currentPath

  // Set cookie for both daily premium experiments (they use the same one for BoF experience)
  useEffect(() => {
    if (setCookieForFE) {
      const cookieValue = 'final-expense'
      Cookies.set('landing_page_flow', cookieValue, {
        expires: 180, //expire in 180 days
      })
    }
  }, [setCookieForFE])
  // --------------------- End: Final Expense Landing Page Cookie ---------------------

  // --------------------- Start: Daily Premium ToF experiments ---------------------

  // This FB AD LP does not use an optimizely experiment, but we still want the BoF cookie
  const fbAdLpPath = '/life/life-insurance-today/'
  const setCookieForFbAdLp = fbAdLpPath === currentPath

  // Set cookie for both daily premium experiments (they use the same one for BoF experience)
  useEffect(() => {
    if (setCookieForFbAdLp) {
      const cookieValue = DAILY_DOLLARS_COOKIE.EW_VALUE
      Cookies.set(DAILY_DOLLARS_COOKIE.NAME, cookieValue, {
        expires: 60, //expire in 60 days
      })
    }
  }, [setCookieForFbAdLp])
  // --------------------- End: Daily Premium ToF experiments ---------------------

  // --- Start: Asta Cookie ---------------------
  useEffect(() => {
    if (isAstaLayout) {
      const cookieValue = ASTA.VARIATIONS.TREATMENT
      Cookies.set(ASTA.COOKIE_NAME, cookieValue, {
        expires: 60, //expire in 60 days
      })
    }
  }, [isAstaLayout])
  // --- End: Asta Cookie ---------------------

  // Start: FE widget update ----------

  const {
    isTreatment1,
    isTreatment2,
    isLoading: isLoadingFeWidget,
    pageKeys,
  } = useUpdatedCpFeLp()

  const {
    isTreatment1: isWillsTreatment1,
    isTreatment3: isWillsTreatment3,
    isLoading: isOnWillsTreatmentLoading,
    pageKeys: willsPageKeys,
  } = useWillsLPExperiment()

  const shouldHideFeWidgetModule = useShouldHideModule({
    isTreatment: isTreatment1 || isTreatment2,
    isLoading: isLoadingFeWidget,
    pageKeys,
    expKey,
  })

  const {
    isOnTreatment: isOnWillsV2_2Treatment,
    isLoading: isOnWillsV2_2TreatmentLoading,
  } = useWillsLPV2_2Experiment()

  // Commented out until launching v2.2 painted doors
  // const shouldHideWillsModule = useShouldHideModule({
  //   isTreatment: isWillsTreatment1 || isWillsTreatment3 || isOnWillsV2_2Treatment,
  //   isLoading: isOnWillsTreatmentLoading || isOnWillsV2_2TreatmentLoading,
  //   pageKeys: willsPageKeys,
  //   expKey,
  // })

  const shouldHideWillsModule = useShouldHideModule({
    isTreatment: isWillsTreatment3,
    isLoading: isOnWillsTreatmentLoading,
    pageKeys: willsPageKeys,
    expKey,
  })

  // Start: LP Hero Update ----------

  const { isTreatment: isPaidLandersTreatment } = usePaidLandersUpdate()

  const isValidExpKey = expKey && expKey.includes(PAID_LANDERS_UPDATE.name)
  const isPaidLandersOffModule =
    expKey === PAID_LANDERS_UPDATE.ruleKeys.on && !isPaidLandersTreatment
  const isPaidLandersOnModule =
    expKey === PAID_LANDERS_UPDATE.ruleKeys.off && isPaidLandersTreatment

  const hidePaidLandersModule =
    (isValidExpKey && isPaidLandersOnModule) || isPaidLandersOffModule

  // End: LP Hero Update ----------

  if (
    !Component ||
    hidePaidLandersModule ||
    shouldHideFeWidgetModule ||
    shouldHideWillsModule
  ) {
    return null // don't show the module
  }
  return (
    <div className={className}>
      <Component moduleData={moduleData} settings={settings} {...rest} />
    </div>
  )
}

export default DynamicClientComponent
