import React, {
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useUserData } from '@/shared/Estimate/useUserData'
import { isValidCoverage } from '@/shared/Estimate/utils'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { Product } from 'lib/@getethos/constants'
import { ageNearestBirthDate } from 'lib/@getethos/utils/ageNearestBirthDate'
import { getQueryString } from 'lib/@getethos/utils/utils'
import { debounce } from 'lodash'

import { useNavigateToApp } from '@/hooks/useNavigateToApp'
import useSiteApiUrl from '@/hooks/useSiteApiUrl'

import { EstimateWidgetImageLayout } from '../EstimateWidget'
import { useFetchQuotes } from '../hooks/useFetchQuotes'
import { useFetchQuotesV2 } from '../hooks/useFetchQuotesV2/useFetchQuotesV2'
import { useGetRecommendations } from '../hooks/useGetRecommendations'
import { UserData } from '../types/user'
import { EstimateWidgetProgress } from '../types/widget'
import { useEstimateWidgetContext } from './EstimateWidgetContext'

type UserSelection = {
  coverage: number
  term: number
}

interface EstimateQuotesProviderProps {
  moduleData: Record<string, any>
  children: React.ReactNode
}

interface Ranges {
  min: number
  max: number
}

interface EstimateQuotesContextProps {
  error: string | null
  setError: (error: string | null) => void
  userSelectedValues: UserSelection | null
  handleCoverageChange: (coverage: number) => void
  handleTermChange: (term: number) => void
  handleGoToMainApp: (props: NavigateToMainAppProps) => void
  maxCoverage: number
  minCoverage: number
  ranges: Ranges
  previousRanges: Ranges
  terms: Array<number>
  product: Product
}
interface NavigateToMainAppProps {
  url?: string
  openInNewTab?: boolean
  needs?: boolean
  ratesCopy?: boolean
  flow?: string
  prefillValues?: Record<string, any>
}

const defaultEstimateQuotesContext: EstimateQuotesContextProps = {
  error: null,
  setError: () => {},
  userSelectedValues: null,
  handleCoverageChange: () => {},
  handleTermChange: () => {},
  handleGoToMainApp: (props: NavigateToMainAppProps) => {},
  maxCoverage: 0,
  minCoverage: 0,
  ranges: { min: 0, max: 0 },
  previousRanges: { min: 0, max: 0 },
  terms: [],
  product: Product.Term,
}

const EstimateQuotesContext = createContext<EstimateQuotesContextProps>(
  defaultEstimateQuotesContext
)

export const useEstimateQuotesContext = () => useContext(EstimateQuotesContext)

const getUserSelectionDefaultValue = (
  widgetProgress: EstimateWidgetProgress,
  recommendedCoverage: number,
  recommendedTerm: number,
  alreadySetDefaulValue?: MutableRefObject<boolean>
): UserSelection => {
  const shouldFireAnalyticsEvent =
    widgetProgress === EstimateWidgetProgress.PRICES &&
    alreadySetDefaulValue &&
    !alreadySetDefaulValue.current

  if (shouldFireAnalyticsEvent) {
    alreadySetDefaulValue.current = true
    estimateWidgetAnalytics.defaultCoverageAndTermSet({
      properties: {
        coverage: recommendedCoverage,
        term: recommendedTerm,
      },
    })
  }
  return {
    coverage: recommendedCoverage,
    term: recommendedTerm,
  }
}
// TODO: Refactor this into more generic functions
const isFinalExpenseCoverageValid = (birthDate: string, coverage: number) => {
  const age = !Number.isNaN(Number(birthDate))
    ? Number(birthDate)
    : ageNearestBirthDate({ birthDate })

  if (age && age <= 70) {
    return coverage >= 5000 && coverage <= 100000
  }

  if (age && age > 70 && age < 76) {
    return coverage >= 5000 && coverage <= 50000
  }
  if (age && age >= 76) {
    return coverage >= 5000 && coverage <= 25000
  }
}

export const getValidCoverage = (
  birthDate: string,
  product: Product,
  minCoverage: number,
  coverage?: number,
  isFinalExpense?: boolean
) => {
  if (!coverage) return null
  if (isFinalExpense) {
    return isFinalExpenseCoverageValid(birthDate, coverage)
      ? coverage
      : minCoverage
  }
  const isValid = isValidCoverage(coverage, birthDate, product)
  return isValid ? coverage : minCoverage
}

const debouncedCoverageChange = debounce(
  (coverage: number, term: number, previousCoverage: number) => {
    estimateWidgetAnalytics.coverageChanged({
      properties: {
        previousCoverage,
        coverage,
        term,
      },
    })
  },
  500
)

const debounceTermChange = debounce(
  (coverage: number, term: number, previousTerm: number) => {
    estimateWidgetAnalytics.termChanged({
      properties: {
        previousTerm,
        coverage,
        term,
      },
    })
  },
  500
)

export const EstimateQuotesProvider: React.FC<EstimateQuotesProviderProps> = ({
  children,
  moduleData,
}) => {
  const {
    userDataControls: { userData, setUserData },
    widgetProgressControls: { widgetProgress, setWidgetProgress },
  } = useEstimateWidgetContext()

  const isFinalExpense =
    moduleData.imageLayout === EstimateWidgetImageLayout.FinalExpense

  const alreadySetDefaultValue = useRef(false)
  const [defaultValuesSet, setDefaultValuesSet] = useState(false)

  const { handleNavigateToApp } = useNavigateToApp()

  const query = getQueryString()

  const { userData: prefiledUserData, isUserDataComplete } = useUserData(
    estimateWidgetAnalytics,
    query,
    {},
    useSiteApiUrl()
  )

  useEffect(() => {
    const isPrefilled = Object.keys(prefiledUserData || {}).length > 1
    if (!isPrefilled) return
    setUserData({
      smoker: prefiledUserData?.smoker === 'true',
      birthDate: prefiledUserData?.birthDate,
      zipCode: prefiledUserData?.zipCode,
      gender: prefiledUserData?.gender,
      estimatedCredit: prefiledUserData?.estimatedCredit,
      extraUserData: prefiledUserData?.extraUserData,
    })
  }, [prefiledUserData])

  useEffect(() => {
    if (!isUserDataComplete) return
    estimateWidgetAnalytics.userPrefilled()
    setWidgetProgress(EstimateWidgetProgress.PRICES)
  }, [isUserDataComplete])

  const {
    recommendedCoverage,
    recommendedTerm,
    maxCoverage,
    minCoverage,
    product,
    region,
    terms,
  } = useGetRecommendations({
    userData,
    featureFlagConfig: {
      useMedianValues: moduleData?.showMedianCoverage,
      useDailyPremium: moduleData?.dailyPremium,
      finalExpense:
        moduleData.imageLayout === EstimateWidgetImageLayout.FinalExpense,
    },
  })

  const defaultUserSelection = useMemo(() => {
    return getUserSelectionDefaultValue(
      widgetProgress,
      recommendedCoverage,
      recommendedTerm,
      alreadySetDefaultValue
    )
  }, [widgetProgress, recommendedCoverage, recommendedTerm])

  const [error, setError] = useState<string | null>(null)
  const [userSelectedValues, setUserSelectedValues] =
    useState<UserSelection | null>(null)
  // set default values when user is in the prices step
  useEffect(() => {
    if (widgetProgress === EstimateWidgetProgress.PRICES && !defaultValuesSet) {
      setUserSelectedValues(defaultUserSelection)
    }
  }, [defaultUserSelection, defaultValuesSet, widgetProgress])

  // There are cases where the region is not set in the userData, but we have it from recommendations
  useEffect(() => {
    if (region && !userData.region) {
      setUserData({ region })
    }
  }, [region, userData.region])

  // If user changes the data in the info step, we need to update the coverage and term
  const updatedCoverage = useMemo(() => {
    return getValidCoverage(
      userData?.birthDate || '',
      product,
      minCoverage,
      userSelectedValues?.coverage,
      isFinalExpense
    )
  }, [
    userSelectedValues?.coverage,
    userData?.birthDate,
    product,
    minCoverage,
    isFinalExpense,
  ])

  // If user changes the data in the info step, we need to update the coverage and term
  const updatedTerm = useMemo(() => {
    const selectedTerm = userSelectedValues?.term
    if (!selectedTerm || !terms) return null

    const closestTerm = terms.reduce((prev, curr) =>
      Math.abs(curr - selectedTerm) < Math.abs(prev - selectedTerm)
        ? curr
        : prev
    )

    return terms.includes(selectedTerm) ? selectedTerm : closestTerm
  }, [userSelectedValues?.term, terms])

  const updatedUserSelection = useMemo(() => {
    if (!updatedCoverage || !updatedTerm) return null
    return {
      coverage: updatedCoverage,
      term: updatedTerm,
    }
  }, [updatedCoverage, updatedTerm])

  useEffect(() => {
    const hasAllRequiredValues =
      userSelectedValues?.coverage &&
      userSelectedValues?.term &&
      updatedUserSelection?.coverage &&
      updatedUserSelection?.term

    if (!hasAllRequiredValues) return

    const shouldUpdate =
      userSelectedValues?.coverage !== updatedUserSelection?.coverage ||
      userSelectedValues?.term !== updatedUserSelection?.term

    if (!shouldUpdate) return

    setUserSelectedValues({
      coverage: updatedUserSelection?.coverage,
      term: updatedUserSelection?.term,
    })
  }, [updatedUserSelection, userSelectedValues])

  const { ranges, previousRanges } = useFetchQuotes({
    userData,
    userSelectedValues: updatedUserSelection,
    product,
    region,
    progress: widgetProgress,
    hasFetchedInitialQuotes: defaultValuesSet,
    setError,
    setHasFetchedInitialQuotes: setDefaultValuesSet,
    ignoreFetch: isFinalExpense,
  })

  // In the future we should migrate to the new fetch quotes endpoint.
  const { ranges: rangesV2, previousRanges: previousRangesV2 } =
    useFetchQuotesV2({
      userData,
      userSelectedValues: updatedUserSelection,
      region,
      progress: widgetProgress,
      hasFetchedInitialQuotes: defaultValuesSet,
      setError,
      setHasFetchedInitialQuotes: setDefaultValuesSet,
      featureFlagConfig: {
        finalExpense: isFinalExpense,
      },
    })

  const handleDebounceCoverageChange = useCallback(
    (coverage: number, term: number, previousCoverage: number) => {
      debouncedCoverageChange(coverage, term, previousCoverage)
    },
    []
  )

  const handleDebounceTermChange = useCallback(
    (term: number, coverage: number, previousTerm: number) => {
      debounceTermChange(coverage, term, previousTerm)
    },
    []
  )

  const handleCoverageChange = useCallback(
    (coverage: number) => {
      const coverageToSet = coverage < maxCoverage ? coverage : maxCoverage
      const term = userSelectedValues?.term

      if (coverageToSet === userSelectedValues?.coverage) return

      handleDebounceCoverageChange(
        coverageToSet,
        term || terms[0],
        userSelectedValues?.coverage || 0
      )
      setUserSelectedValues({ coverage: coverageToSet, term: term || terms[0] })
      setDefaultValuesSet(true)
    },
    [
      handleDebounceCoverageChange,
      maxCoverage,
      terms,
      userSelectedValues?.coverage,
      userSelectedValues?.term,
    ]
  )

  const handleTermChange = useCallback(
    (term: number) => {
      const closest = terms.reduce((prev, curr) => {
        return Math.abs(curr - term) < Math.abs(prev - term) ? curr : prev
      })

      if (closest === userSelectedValues?.term) return

      handleDebounceTermChange(
        closest,
        userSelectedValues?.coverage || 0,
        userSelectedValues?.term || 0
      )
      setDefaultValuesSet(true)
      setUserSelectedValues({
        coverage: userSelectedValues?.coverage || 0,
        term: closest,
      })
    },
    [
      handleDebounceTermChange,
      terms,
      userSelectedValues?.coverage,
      userSelectedValues?.term,
    ]
  )

  const handleGoToMainApp = useCallback(
    ({
      url = '',
      openInNewTab = false,
      needs = false,
      ratesCopy = false,
      flow = '',
      prefillValues,
    }: NavigateToMainAppProps) => {
      const userDataToSend = prefillValues
        ? {
            ...userData,
            ...prefillValues,
          }
        : userData

      estimateWidgetAnalytics.goToMainApp({
        properties: {
          coverage: userSelectedValues?.coverage,
          term: userSelectedValues?.term,
          minPrice: ranges.min,
          maxPrice: ranges.max,
          userFormData: userDataToSend,
          initialCoverage: recommendedCoverage,
          initialTerm: recommendedTerm,
        },
      })
      if (isFinalExpense) {
        delete userData.region
      }

      handleNavigateToApp(
        userDataToSend,
        openInNewTab,
        needs,
        ratesCopy,
        url,
        flow
      )
    },
    [
      handleNavigateToApp,
      isFinalExpense,
      ranges.max,
      ranges.min,
      recommendedCoverage,
      recommendedTerm,
      userData,
      userSelectedValues?.coverage,
      userSelectedValues?.term,
    ]
  )

  const rangesToUse = isFinalExpense ? rangesV2 : ranges
  const previousRangesToUse = isFinalExpense ? previousRangesV2 : previousRanges

  const values = useMemo(() => {
    return {
      error,
      setError,
      userSelectedValues,
      handleCoverageChange,
      handleTermChange,
      handleGoToMainApp,
      maxCoverage,
      minCoverage,
      ranges: rangesToUse,
      previousRanges: previousRangesToUse,
      terms,
      product,
    }
  }, [
    error,
    userSelectedValues,
    handleCoverageChange,
    handleTermChange,
    handleGoToMainApp,
    maxCoverage,
    minCoverage,
    previousRangesToUse,
    rangesToUse,
    terms,
    product,
  ])

  return (
    <EstimateQuotesContext.Provider value={values}>
      {children}
    </EstimateQuotesContext.Provider>
  )
}
