import React, { PropsWithChildren, useEffect, useState } from 'react'

import dynamic from 'next/dynamic'

import { LEGAL_BANNER_COOKIE, LEGAL_BANNER_COOKIE_VALUE } from '@/constants'
import { FootnoteFooterProvider, UrlContext } from '@/contexts'
import { LegalBannerProvider } from '@/contexts/LegalBannerContext'
import { useModuleContext } from '@/contexts/ModuleContext'
import WillsModalProvider from '@/contexts/WillsModalContext'
import { CloudinaryImage, Layout } from 'ethos-design-system'
import Cookies from 'js-cookie'
import useTrackMaxScroll from 'lib/@getethos/analytics/useTrackMaxScroll'

import { Navbar } from '@/components/Navigation/Navbar'
import { CmsFooterScripts } from '@/components/global/Footer/CmsFooterScripts'
import LegalBanner from '@/components/global/LegalBanner/LegalBanner'
import PriorityImage from '@/components/global/PriorityImage'

import { useForbesHeaderNavbar } from '@/hooks/cobranded/useForbesHeaderNavbar'
import { useForbesUsaTodayNavbar } from '@/hooks/cobranded/useForbesUsaTodayNavbar'
import { useIsSofi } from '@/hooks/cobranded/useIsSofi'
import { useBestMoneyHeader } from '@/hooks/features/useBestMoneyHeader'
import { useCreditKarmaHeader } from '@/hooks/features/useCreditKarmaHeader'
import { usePaidLandersUpdate } from '@/hooks/features/usePaidLandersUpdate'

import {
  BestMoneyLogo,
  CreditKarmaLogo,
  ForbesLogo,
  ForbesLogoMobile,
  ForbesUsaTodayLogo,
} from '../icons'

const Footer = dynamic(() => import('@/components/Footer'))

interface CmsLayoutProps {
  pageContent: any
  settings: Record<string, any>
  footnoteFooterInfo: any
  url: string
  noNavbar?: boolean
  navbarLogoOnly?: boolean
  noFooter?: boolean
  noCookieDisclaimer?: boolean
  agentLayout?: boolean
  shouldLoadAgentsIFrameScript?: boolean
  noLegalBanner?: boolean
  isAstaLayout?: boolean
}

export const CMSLayout: React.FC<PropsWithChildren<CmsLayoutProps>> = ({
  pageContent,
  settings,
  noLegalBanner,
  noNavbar,
  navbarLogoOnly,
  noFooter,
  footnoteFooterInfo,
  url,
  agentLayout,
  shouldLoadAgentsIFrameScript = false,
  children,
  isAstaLayout = false,
}) => {
  useTrackMaxScroll()

  const [showLegalBanner, setShowLegalBanner] = useState(false)
  const { isTreatment: isPaidLandersTreatment } = usePaidLandersUpdate()
  const isForbesHeaderEnabled = useForbesHeaderNavbar()
  const isForbesUsaTodayHeaderEnabled = useForbesUsaTodayNavbar()

  const {
    footerControls: { isFooterHidden },
  } = useModuleContext()

  useEffect(() => {
    if (
      Cookies.get(LEGAL_BANNER_COOKIE) !== LEGAL_BANNER_COOKIE_VALUE &&
      !noLegalBanner
    ) {
      setShowLegalBanner(true)
    }
  }, [noLegalBanner])

  // Start header feature flags
  const { isEnabled: isBestMoneyHeaderEnabled } = useBestMoneyHeader()
  const { isEnabled: isCreditKarmaHeaderEnabled } = useCreditKarmaHeader()

  // TODO admin yaml setting for partner logo
  const isSofi = useIsSofi()

  const SoFiLogo = () => (
    <PriorityImage
      publicId={
        'https://res.cloudinary.com/getethos/image/upload/v1706242187/sofi_high_res_logo_tlybpp.png'
      }
      alt={'SoFi Logo'}
      crop={CloudinaryImage.CROP_METHODS.FIT}
      width={[100, 100, 133, 133]}
      height={[16, 16, 22, 22]}
    />
  )

  let partnerLogo = null
  let partnerLogoMobile = null

  if (isForbesHeaderEnabled) {
    partnerLogo = <ForbesLogo />
    partnerLogoMobile = <ForbesLogoMobile />
  } else if (isSofi) {
    partnerLogo = <SoFiLogo />
    partnerLogoMobile = <SoFiLogo />
  } else if (isBestMoneyHeaderEnabled) {
    partnerLogo = <BestMoneyLogo />
    partnerLogoMobile = <BestMoneyLogo />
  } else if (isCreditKarmaHeaderEnabled) {
    partnerLogo = <CreditKarmaLogo />
    partnerLogoMobile = <CreditKarmaLogo />
  } else if (isForbesUsaTodayHeaderEnabled) {
    partnerLogo = <ForbesUsaTodayLogo />
    partnerLogoMobile = <ForbesUsaTodayLogo />
  }

  // End SoFi Cobranded header experiments

  const shouldHideAccessibilityWidget = url.includes('external-estimate-widget')

  return (
    <>
      <WillsModalProvider>
        <LegalBannerProvider>
          <FootnoteFooterProvider footnoteFooterInfo={footnoteFooterInfo}>
            <UrlContext.Provider value={url}>
              <Layout>
                <Navbar
                  agentLayout={agentLayout || pageContent.agentLayout}
                  noNavbar={noNavbar || pageContent.noNavbar}
                  navbarLogoOnly={navbarLogoOnly || pageContent.navbarLogoOnly}
                  animatedNavbar={pageContent.animatedNavbar} // todo rename globally in separate pr to navbarOptions
                  navbarCtaOverride={pageContent.navbarCtaOverride}
                  hideDesktopCta={pageContent.hideDesktopCta}
                  partnerLogo={partnerLogo}
                  partnerLogoMobile={partnerLogoMobile}
                  partnerLogoSeparator={isSofi ? 'pipe' : 'plus'}
                  settings={settings}
                  isAstaLayout={isAstaLayout}
                  isSofi={isSofi}
                />
                <main>{children}</main>
                <Footer
                  noFooter={
                    ((noFooter || pageContent.noFooter || isAstaLayout) &&
                      !isPaidLandersTreatment) ||
                    isFooterHidden
                  }
                  partnerFooter={pageContent.partnerFooter}
                  blankFooter={pageContent.blankFooter}
                  agentLayout={!!agentLayout}
                  settings={settings}
                  footnoteFooter={pageContent.footnoteFooter}
                />
              </Layout>
            </UrlContext.Provider>
          </FootnoteFooterProvider>
          {showLegalBanner && (
            <LegalBanner
              settings={settings}
              isGFI={pageContent.isGFILegalBannerColor}
            />
          )}
        </LegalBannerProvider>
      </WillsModalProvider>
      <CmsFooterScripts
        shouldLoadAgentsIFrameScript={shouldLoadAgentsIFrameScript}
        hideAccessibilityWidget={shouldHideAccessibilityWidget}
      />
    </>
  )
}
