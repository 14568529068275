import React, { useState } from 'react'

import {
  Button,
  COLORS,
  Form,
  TitleLarge2,
  TitleMedium2,
  TitleSmall2,
} from 'ethos-design-system'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { getStateFromGoogleApi } from 'lib/GoogleMapsApi/utils'

import { EstimateWidgetModuleData } from '../../EstimateWidget'
import { DATA_TID } from '../../common/data-tid'
import { scrollToTop } from '../../common/scrollToTop'
import { useEstimateQuotesContext } from '../../contexts/EstimateQuotesContext'
import { useEstimateWidgetContext } from '../../contexts/EstimateWidgetContext'
import {
  Errors,
  ExperimentalFieldWrapper,
  translateErrors,
} from '../../experiments/formOverhauls'
import experimentalStyles from '../../experiments/formOverhauls.module.scss'
import { UserData } from '../../types/user'
import { EstimateWidgetProgress } from '../../types/widget'
import styles from './FloatingInfoFormRefactor.module.scss'
import { SofiDisclaimer } from './components/partners/SofiDisclaimer'
import { FinalExpenseInfoWrapper } from './experiments/FinalExpenseInfoForm/FinalExpenseInfoWrapper'

type Variations = {
  salamanderForm?: boolean
  cypressForm?: boolean
  asta: boolean
  priceElasticityEnabled?: boolean
  boxShadow: boolean
  displayEstimatedCredit?: boolean
  estimateCopy: string
  finalExpense?: boolean
}

interface FloatingInfoFormProps {
  variations: Variations
  ctaText: string
  scrollNode: Element
  fields: Record<string, unknown>
  moduleData: EstimateWidgetModuleData
}

export const FloatingInfoFormRefactored: React.FC<FloatingInfoFormProps> = ({
  variations,
  ctaText,
  scrollNode,
  fields,
  moduleData,
}) => {
  const [fieldErrors, setErrors] = useState<Errors>({})

  const {
    userDataControls: { setUserData, userData },
    widgetProgressControls: { setWidgetProgress },
  } = useEstimateWidgetContext()

  const { userSelectedValues } = useEstimateQuotesContext()

  const {
    asta,
    boxShadow,
    cypressForm,
    displayEstimatedCredit,
    priceElasticityEnabled,
    salamanderForm,
    estimateCopy,
  } = variations

  const goToResults = () => {
    setWidgetProgress(EstimateWidgetProgress.PRICES)
    estimateWidgetAnalytics.continueToPrices({
      properties: {
        priceElasticityEnabled,
        triedToEditAnswers: !!userSelectedValues,
      },
    })
  }

  // provide a customized onSubmit() function to modify the value
  // of smoker to 'false' which backend API accepts, for the lean
  // variation, where the checkbox initial value = undefined
  const onSubmit = async (formValues: UserData) => {
    const modifiedValues = {
      ...formValues,
      smoker: !!formValues.smoker,
    }

    const shouldFetchRegion =
      !userData?.region ||
      Number(userData.zipCode) !== Number(modifiedValues.zipCode)

    try {
      if (shouldFetchRegion) {
        setWidgetProgress(EstimateWidgetProgress.LOADING)
        const region = await getStateFromGoogleApi(
          modifiedValues.zipCode,
          estimateWidgetAnalytics
        )

        if (region === 'NY') {
          window.location.assign(
            `https://www.ethoslife.com/life/youre-in-new-york/`
          )
          return
        }

        setUserData({
          ...modifiedValues,
          region,
        })
        goToResults()
      } else {
        setUserData(modifiedValues)
        goToResults()
      }
    } catch (error) {
      console.error('Error fetching region from Google API', error)
    }
  }

  const onClick =
    (getFieldErrors: () => Errors): (() => void) =>
    (): void => {
      scrollToTop(scrollNode)
      if (salamanderForm || cypressForm) {
        setErrors(translateErrors(getFieldErrors()))
      }
    }

  let containerStyles = 'pr-6 pb-6 pl-6 bg-white rounded-lg max-w-sm'
  if (asta) {
    containerStyles =
      'pt-none md:pt-6 px-none md:px-6 pb-6 bg-white rounded-lg max-w-xl'
  } else {
    containerStyles += boxShadow ? ' shadow-md' : ''
    containerStyles += estimateCopy ? ' pt-6' : ' pt-8'
  }

  containerStyles +=
    salamanderForm || cypressForm ? ` ${experimentalStyles.suppressErrors}` : ''

  let fieldWrapper = ''
  fieldWrapper += asta
    ? 'border border-solid border-1 border-gray-2 rounded-sm p-6'
    : ''

  let CTAButton = salamanderForm
    ? Button.Medium.Salamander
    : cypressForm
    ? Button.Medium.Cypress
    : Button.Medium.Black

  if (asta) {
    CTAButton = Button.Medium.DarkSalamander
  }

  const isFinalExpense = variations.finalExpense

  if (isFinalExpense) return <FinalExpenseInfoWrapper moduleData={moduleData} />

  return (
    <Form
      key={JSON.stringify(userData)}
      config={{
        formName: 'User Info Form',
        formId: 'UserInfoFormFloating',
        autocompleteOff: true,
        onSubmit: onSubmit,
        fields,
      }}
    >
      {/*  This is because EDSv1 is not super friendly with TS */}
      {/* @ts-ignore */}
      {({ field, getFieldErrors, getFormIsValid }) => {
        const ctaDisabled = !salamanderForm && !cypressForm && !getFormIsValid()
        const formStyles = [styles.infoForm]
        if (asta) {
          formStyles.push(styles.asta)
        }
        return (
          <div data-tid={DATA_TID.FIELDS1} className={formStyles.join(' ')}>
            <div className={containerStyles}>
              {asta ? (
                <>
                  {estimateCopy && (
                    <h3 className="px-4 pb-6 pt-4 text-center md:pb-12">
                      <div className={'hidden md:block'}>
                        <TitleLarge2.Serif.Book500 color={COLORS.GRAY_PRIMARY}>
                          {estimateCopy}
                        </TitleLarge2.Serif.Book500>
                      </div>
                      <div className={'block md:hidden'}>
                        <TitleMedium2.Serif.Book500 color={COLORS.GRAY_PRIMARY}>
                          {estimateCopy}
                        </TitleMedium2.Serif.Book500>
                      </div>
                    </h3>
                  )}
                </>
              ) : (
                <>
                  {estimateCopy && (
                    <h3 className="pb-6 text-center">
                      <TitleSmall2.Sans.Medium500 color={COLORS.GRAY_PRIMARY}>
                        {estimateCopy}
                      </TitleSmall2.Sans.Medium500>
                    </h3>
                  )}
                </>
              )}

              <div className={fieldWrapper}>
                <ExperimentalFieldWrapper
                  className="pb-6"
                  enabled={salamanderForm || cypressForm}
                  errorCopy={fieldErrors.gender}
                  field={field('gender')}
                />

                <div className="flex pb-6">
                  <ExperimentalFieldWrapper
                    className="mr-2 w-1/2"
                    enabled={salamanderForm || cypressForm}
                    errorCopy={fieldErrors.birthDate}
                    field={field('birthDate')}
                  />

                  <ExperimentalFieldWrapper
                    className="w-1/2"
                    enabled={salamanderForm || cypressForm}
                    errorCopy={fieldErrors.zipCode}
                    field={field('zipCode')}
                  />
                </div>

                {displayEstimatedCredit && (
                  <div className="flex pb-6">
                    <ExperimentalFieldWrapper
                      className="mr-2 w-full"
                      enabled={salamanderForm || cypressForm}
                      errorCopy={fieldErrors.estimatedCredit}
                      field={field('estimatedCredit')}
                    />
                  </div>
                )}
                <div className="pb-6">{field('smoker')}</div>
                <div className={asta ? styles.astaSubmitButton : ''}>
                  <CTAButton
                    disabled={ctaDisabled}
                    arrowIcon={!asta}
                    data-tid={DATA_TID.SUBMIT_BUTTON}
                    type={getFormIsValid() ? 'submit' : 'button'}
                    onClick={onClick(getFieldErrors)}
                    fullWidth={true}
                  >
                    {ctaText}
                  </CTAButton>
                </div>
              </div>
              <SofiDisclaimer />
            </div>
          </div>
        )
      }}
    </Form>
  )
}
