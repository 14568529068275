import React from 'react'

import { ESTIMATE_WIDGET_AGES } from '@/constants'
import {
  EstimatedCredit,
  estimatedCreditOptionsWithoutNumericValue,
} from '@/shared/Estimate/EstimatedCredit'
import {
  ButtonSelectGroup,
  COLORS,
  CheckboxInput,
  NumberInput,
  OPTION_BUTTON_STYLES,
  ZipInput,
} from 'ethos-design-system'
import validateNumberRange from 'ethos-design-system/src/validators/NumberInputValidator'
import validateExists from 'ethos-design-system/src/validators/validateExists'
import { ageFromBirthDate } from 'lib/@getethos/utils/ageFromBirthDate'

import { DATA_TID } from '../../common/data-tid'
import { UserData } from '../../types/user'

type EstimatedCreditType = {
  estimatedCreditFootnote: string
  variation: string
  estimatedCreditQuestion: string
  displayEstimatedCredit: boolean
}

type Labels = {
  genderQuestion: string
  nicotineQuestion: string
  birthdateQuestion: string
  zipcodeQuestion: string
}

type FeatureConfig = {
  hideNicotineQuestion: boolean
}

interface FloatingFields {
  userData: UserData
  estimatedCredit: EstimatedCreditType
  labels: Labels
  analytics: any
  featureConfig: FeatureConfig
}

/**
 * returns a function to be called as child by Form.js field
 * that function returns a ButtonSelectGroup injected with required props
 * @param initialValue
 * @param labelCopy
 * @returns {function(*, *)}
 */
function floatingButtonFactory(
  initialValue: any,
  labelCopy: string
): (
  props: object,
  options: [{ value: string; copy: string }]
) => React.ReactNode {
  return function (props, options) {
    return (
      <div className={'w-full'}>
        <ButtonSelectGroup
          labelCopy={labelCopy}
          {...props}
          buttonStyle={OPTION_BUTTON_STYLES.FLOATING}
          allCaps={false}
          initialValue={initialValue}
          capitalize={false}
          labelColor={COLORS.GRAY_SECONDARY}
          labelWeight={'regular'}
        >
          {options.map((option) => (
            <ButtonSelectGroup.Option
              value={option.value}
              key={`buttonSelectGroup${option.value}`}
            >
              {option.copy}
            </ButtonSelectGroup.Option>
          ))}
        </ButtonSelectGroup>
      </div>
    )
  }
}

export const getFloatingFieldsRefactor = ({
  userData,
  labels,
  analytics,
  estimatedCredit,
  featureConfig: { hideNicotineQuestion },
}: FloatingFields) => {
  const initialValues = userData
  const age: string | undefined = ageFromBirthDate({
    birthDate: initialValues.birthDate,
  })

  const {
    birthdateQuestion,
    genderQuestion,
    nicotineQuestion,
    zipcodeQuestion,
  } = labels

  const {
    estimatedCreditFootnote,
    variation,
    estimatedCreditQuestion,
    displayEstimatedCredit,
  } = estimatedCredit

  const gender = {
    component: floatingButtonFactory(initialValues.gender, genderQuestion),
    validators: [validateExists],
    validationSuccess: [analytics],
    options: [
      { value: 'Female', copy: 'Female' },
      { value: 'Male', copy: 'Male' },
    ],
    tid: DATA_TID.GENDER_INPUT,
  }

  const smoker = {
    component: (formProps: any) => {
      return (
        <div className={'w-full'}>
          <CheckboxInput initialValue={initialValues.smoker} {...formProps}>
            {nicotineQuestion}
          </CheckboxInput>
        </div>
      )
    },
    optional: true,
    validators: [],
    tid: DATA_TID.NICOTINE_INPUT,
  }

  const birthDate = {
    component: (formProps: any, options: any) => {
      return (
        <NumberInput
          {...formProps}
          initialValue={age}
          name={options.name}
          placeholder={options.placeholder}
          labelCopy={birthdateQuestion}
          allCaps={false}
          capitalize={true}
          maxLength={3}
          classOverrides={'bg-GrayLightHover--opaque rounded-sm border-none'}
          labelColor={COLORS.GRAY_SECONDARY}
          labelWeight={'regular'}
        />
      )
    },
    tid: DATA_TID.BIRTHDATE_INPUT,
    validators: [
      validateExists,
      validateNumberRange(
        ESTIMATE_WIDGET_AGES.MIN,
        ESTIMATE_WIDGET_AGES.MAX,
        `Sorry, you must be between ${ESTIMATE_WIDGET_AGES.MIN} - ${ESTIMATE_WIDGET_AGES.MAX} `
      ),
    ],
    validationSuccess: [analytics],

    options: {
      placeholder: '',
      name: 'birthdate-age',
    },
  }

  const zipCode = {
    component: (props: any) => {
      return (
        <ZipInput
          initialValue={initialValues.zipCode}
          {...props}
          allCaps={false}
          capitalize={true}
          labelWeight="regular"
          labelColor={COLORS.GRAY_SECONDARY}
          classOverrides={'bg-GrayLightHover--opaque rounded-sm border-none'}
        />
      )
    },
    validationSuccess: [analytics],
    labelCopy: zipcodeQuestion,
    tid: DATA_TID.ZIPCODE_INPUT,
  }

  const fields: {
    [key: string]: any
  } = {
    gender,
    smoker,
    birthDate,
    zipCode,
  }

  if (displayEstimatedCredit) {
    const estimatedCredit = {
      component: (props: any) => (
        <EstimatedCredit
          estimatedCreditFootnote={estimatedCreditFootnote}
          variation={variation}
          {...props}
        />
      ),
      validators: [validateExists],
      validationSuccess: [analytics],
      labelCopy: estimatedCreditQuestion,
      options: estimatedCreditOptionsWithoutNumericValue,
      tid: DATA_TID.ESTIMATED_CREDIT_INPUT,
      name: 'estimate-credit-input',
    }

    fields['estimatedCredit'] = estimatedCredit
  }

  if (hideNicotineQuestion) {
    delete fields.smoker
  }

  return fields
}
