import { Caption } from 'ethos-design-system'

import { useIsSofi } from '@/hooks/cobranded/useIsSofi'

export const SofiDisclaimer = () => {
  const isSofi = useIsSofi()
  if (!isSofi) return null
  return (
    <div className="mt-2 text-center md:text-left">
      <Caption.Regular400>
        SoFi Life Insurance is connecting you with Ethos to help you find life
        insurance coverage*
      </Caption.Regular400>
    </div>
  )
}
