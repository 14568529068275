import React, { useEffect, useState } from 'react'

import { useLegalBannerContext } from '@/contexts/LegalBannerContext'
import classNames from 'classnames'
import Cookies from 'js-cookie'
import get from 'lodash.get'
import { PrivacyCenter } from 'src/shared/martech/PrivacyCenter'

import { useIsSofi } from '@/hooks/cobranded/useIsSofi'

import { legalBannerAnalytics } from '../../../../lib/@getethos/analytics/analyticsEvents'
import {
  LEGAL_BANNER_COOKIE,
  LEGAL_BANNER_COOKIE_VALUE,
} from '../../../constants'
import { Original } from './Layouts'
import styles from './LegalBanner.module.scss'

type LegalBannerProps = {
  settings: Record<string, any>
  isGFI?: boolean
}

/**
 * LegalBanner - A dismissible fixed overlay containing a legal banner.
 *               Adds a cookie to prevent future display upon dismissal.
 *               Tracks each dismissal in a Segment event.
 *
 * @return {JSX.Element}
 */
const LegalBanner = ({ settings, isGFI }: LegalBannerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { setIsDisclaimerOpen } = useLegalBannerContext()
  const legalBannerData = get(settings, 'legal-banner.md')

  const isSofi = useIsSofi()

  // setup classes for open/dismissed state
  const legalBannerClasses = classNames([
    styles.root,
    isOpen && styles.open,
    (isGFI && styles.gfi) || styles.forest,
  ])

  const dismissBanner = (
    event: React.MouseEvent | React.KeyboardEvent
  ): void => {
    Cookies.set(LEGAL_BANNER_COOKIE, LEGAL_BANNER_COOKIE_VALUE, {
      expires: 365,
    })
    legalBannerAnalytics.legalBannerDismissed()
    setIsOpen(false)
    setIsDisclaimerOpen(false)
    PrivacyCenter.OptIn(event)
  }

  // check if the user has the cookie which indicates they've seen the disclaimer
  useEffect(() => {
    if (Cookies.get(LEGAL_BANNER_COOKIE) !== LEGAL_BANNER_COOKIE_VALUE) {
      setIsOpen(true)
    }
  }, [setIsOpen])

  const mobileContent: string = isSofi
    ? get(legalBannerData, 'legalBannerSofiMobile')
    : get(legalBannerData, 'legalBannerCopyMobile')
  const desktopContent: string = isSofi
    ? get(legalBannerData, 'legalBannerSofiDesktop')
    : get(legalBannerData, 'legalBannerCopyDesktop')

  return (
    <>
      <div className={legalBannerClasses}>
        <Original
          isGFI={isGFI}
          dismissBanner={dismissBanner}
          mobileContent={mobileContent}
          desktopContent={desktopContent}
        />
      </div>
    </>
  )
}

export default LegalBanner
