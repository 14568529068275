import React from 'react'

import classNames from 'classnames'
import {
  Caption,
  CloudinaryImage,
  Footnote,
  Spacer,
  TitleSmall2,
} from 'ethos-design-system'

import Markdown from '@/components/global/Markdown'
import PriorityImage from '@/components/global/PriorityImage'

import { useIsSofi } from '@/hooks/cobranded/useIsSofi'

import { useEstimateWidgetContext } from '../../contexts/EstimateWidgetContext'
import styles from './StraightToApp.module.scss'
import { LayoutProps } from './index'

const StraightToApp = ({
  updateScrollNode,
  moduleData,
  formManager,
  widgetProgress,
  isRefactor,
}: LayoutProps) => {
  const isSofi = useIsSofi()
  const MD = moduleData
  const {
    widgetProgressControls: { widgetProgress: widgetProgressRefactor },
  } = useEstimateWidgetContext()

  const progressToUse = isRefactor ? widgetProgressRefactor : widgetProgress

  let formClasses =
    'StraightToAppForm w-100 mx-auto md:mx-0 md:pb-12 max-w-full'

  if (MD.groupImage && MD.imageAlt) {
    if (MD.displayTrust && !MD.displayEstimatedCredit) {
      formClasses += ' md:mb-32 lg:mb-6'
    } else if (!MD.displayEstimatedCredit) formClasses += ' md:mb-32 lg:mb-24'
  }

  const section = `floatingContainer StraightToApp relative w-full p-4 md:p-8 max-w-md md:max-w-screen-xl mx-auto ${
    isSofi ? '' : '2xl:pb-24'
  }`

  const QuoteIcon = () => (
    <svg
      width="42"
      height="33"
      view-box="0 0 42 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 33C33.1819 33.0029 34.3528 32.7731 35.4459 32.3235C36.5389 31.8739 37.5327 31.2135 38.3705 30.3798C40.0626 28.6962 41.0165 26.4094 41.0225 24.0225C41.0285 21.6355 40.086 19.344 38.4024 17.6519C36.7188 15.9599 34.4319 15.0059 32.045 15C32.2972 10.5075 33.7304 6.16119 36.2 2.39997C36.4215 2.10419 36.526 1.73707 36.4935 1.36898C36.461 1.0009 36.2938 0.657772 36.0239 0.405365C35.754 0.152961 35.4005 0.00905179 35.0311 0.00122781C34.6616 -0.00659235 34.3023 0.122218 34.022 0.362972C33.5735 0.749973 23 9.97047 23 24C23.0024 26.3862 23.9514 28.674 25.6387 30.3613C27.326 32.0486 29.6138 32.9976 32 33Z"
        fill="#82B0AB"
      />
      <path
        d="M9.49999 33C11.8869 33.0059 14.1785 32.0635 15.8705 30.3798C17.5626 28.6962 18.5165 26.4094 18.5225 24.0225C18.5285 21.6355 17.586 19.344 15.9024 17.6519C14.2188 15.9599 11.9319 15.0059 9.545 15C9.79716 10.5075 11.2304 6.16119 13.7 2.39997C13.9215 2.10419 14.026 1.73707 13.9935 1.36898C13.961 1.0009 13.7938 0.657772 13.5239 0.405365C13.254 0.152961 12.9005 0.00905179 12.5311 0.00122781C12.1616 -0.00659235 11.8023 0.122218 11.522 0.362972C11.0735 0.749973 0.499994 9.97047 0.499993 24C0.502375 26.3862 1.45135 28.674 3.13866 30.3613C4.82597 32.0486 7.11378 32.9976 9.49999 33Z"
        fill="#82B0AB"
      />
    </svg>
  )

  const trophyIcon =
    'https://res.cloudinary.com/getethos/image/upload/v1695830597/trophy_cdc5hb.svg'
  const paperAndQuillIcon =
    'https://res.cloudinary.com/getethos/image/upload/v1695831111/paper_and_quill_b6yvfc.svg'
  const paperAndStampIcon =
    'https://res.cloudinary.com/getethos/image/upload/v1695831330/paper_and_stamp_hmg542.svg'
  const lightningBoltIcon =
    'https://res.cloudinary.com/getethos/image/upload/v1695831330/lightning_bolt_ymum4n.svg'
  const familyIcon =
    'https://res.cloudinary.com/getethos/image/upload/v1695831330/family_stick_people_ynj1oi.svg'

  interface ReviewProps {
    review: string
    reviewer: string
    image: string
    widths: Array<number>
    heights: Array<number>
    spacer: boolean
    progress?: string
  }

  const ReviewBox = ({
    review,
    reviewer,
    image,
    widths,
    heights,
    spacer,
    progress,
  }: ReviewProps) => {
    const container =
      progress === 'price'
        ? 'relative mx-auto w-full overflow-hidden rounded-xl bg-gray-6 p-6 md:p-8 z-1 md:pb-8 h-astaMobile md:h-astaDesktop max-w-full sm:max-w-sm md:max-w-xs lg:max-w-full mt-4 md:mt-0 md:mb-4'
        : 'relative mb-4 w-full overflow-hidden rounded-xl bg-gray-6 p-6 md:p-8 z-1 pb-72 md:pb-8'
    return (
      <div className={container}>
        <div>
          <QuoteIcon />
        </div>
        <div className={'w-96 max-w-full pr-12'}>
          <TitleSmall2.Serif.Medium500>
            {review}&rdquo;
          </TitleSmall2.Serif.Medium500>
          <Spacer.H16 />
          <Footnote.Medium500>{reviewer}</Footnote.Medium500>
          {spacer ? (
            <div className={'h-4 md:h-16'} />
          ) : (
            <div className={'h-2 md:h-6'} />
          )}
        </div>
        <div className={'w-36'}>
          <CloudinaryImage
            publicId={
              'https://res.cloudinary.com/getethos/image/upload/v1695833729/trustpilot_with_rating_reviews_sn5rti.png'
            }
            alt={'TrustPilot Review Stars'}
            crop={CloudinaryImage.CROP_METHODS.FIT}
            width={[135, 135, 143, 143]}
            height={[80, 80, 88, 88]}
          />
        </div>
        <div className={`absolute bottom-0 right-0 ${styles.imageContainer}`}>
          <PriorityImage
            publicId={image}
            alt={'Baby with parent'}
            crop={CloudinaryImage.CROP_METHODS.FILL}
            width={widths}
            height={heights}
            className={styles.image}
          />
        </div>
        <div className={styles.grayCurveOverlay}>
          <div className={styles.inset} />
        </div>
      </div>
    )
  }

  const Values = () => {
    return (
      <div
        className={`flex flex-col justify-between rounded-xl bg-gray-6 px-6 py-8 ${styles.values} gap-4 md:gap-0`}
      >
        <div className={'flex flex-row'}>
          <img
            src={paperAndStampIcon}
            alt={'Paper and Stamp Icon'}
            width={76}
            height={76}
            className={'mr-6'}
          />
          <p>Up to $2 million in coverage</p>
        </div>
        <span className={'h-px w-full bg-white'}></span>
        <div className={'flex flex-row items-center'}>
          <img
            src={lightningBoltIcon}
            alt={'Lightning Bolt Icon'}
            width={76}
            height={76}
            className={'mr-6'}
          />
          <p>Get covered in 5 minutes</p>
        </div>
        <span className={'h-px w-full bg-white'}></span>
        <div className={'flex flex-row items-center'}>
          <img
            src={familyIcon}
            alt={'Stick People Family Icon'}
            width={76}
            height={76}
            className={'mr-6'}
          />
          <p>95% customers approved</p>
        </div>
      </div>
    )
  }

  const InfoReview = () => {
    return (
      <ReviewBox
        review={
          'I searched and searched online, and this company, by far, was the best.'
        }
        reviewer={'Dr. Sherry Davis'}
        image={
          'https://res.cloudinary.com/getethos/image/upload/v1663580085/00_CMS/single_cta_social_proof_treatment_1_zoelbe_551942_cropped.png'
        }
        widths={[265, 265, 265, 265]}
        heights={[276, 276, 276, 276]}
        spacer={true}
      />
    )
  }

  return (
    <>
      <section className={section} ref={updateScrollNode}>
        <div
          className={
            'flex flex-col items-center md:flex-row md:items-start md:justify-between md:gap-12'
          }
        >
          {progressToUse === 'info' ? (
            <div
              className={`flex flex-col ${styles.boxes} order-last max-w-full md:order-first`}
            >
              <div className={'hidden md:block'}>
                <InfoReview />
              </div>
              <div
                className={
                  'order-first flex flex-col gap-x-4 md:order-none md:flex-row'
                }
              >
                <div className={`flex flex-col text-center ${styles.perks}`}>
                  <div
                    className={
                      'flex flex-row items-center justify-between gap-4 rounded-t-xl bg-gray-6 px-4 py-6 text-left md:mb-4 md:flex-col md:gap-0 md:rounded-xl md:px-10 md:text-center'
                    }
                  >
                    <img
                      src={trophyIcon}
                      alt={'Trophy Icon'}
                      width={60}
                      height={60}
                    />
                    <p>
                      <span>Ethos is the #1</span> online life insurance
                      provider
                    </p>
                  </div>
                  <div className={'h-px bg-gray-6 px-4 md:hidden'}>
                    <div className={'h-px w-full bg-gray-2'} />
                  </div>
                  <div
                    className={
                      'mb-4 flex flex-row items-center justify-between gap-4 rounded-b-xl bg-gray-6 p-6 px-4 text-left md:mb-0 md:flex-col md:gap-0 md:rounded-xl md:px-10 md:text-center'
                    }
                  >
                    <img
                      src={paperAndQuillIcon}
                      alt={'Paper and Quill Icon'}
                      width={60}
                      height={60}
                    />
                    <p>
                      Free legal will and estate planning{' '}
                      <span>worth $898</span>
                    </p>
                  </div>
                </div>
                <div className={'block md:hidden'}>
                  <InfoReview />
                </div>
                <Values />
              </div>
            </div>
          ) : (
            <div
              className={`flex flex-col ${styles.boxes} order-last max-w-full sm:max-w-md md:order-first lg:max-w-full`}
            >
              <ReviewBox
                review={
                  'It took about 5 minutes and I was insured instantly for 1.2 million.'
                }
                reviewer={'Shirley Walker'}
                image={
                  'https://res.cloudinary.com/getethos/image/upload/v1695754741/00_CMS/woman_holding_baby_z0w2hi.png'
                }
                widths={[384, 384, 592, 592]}
                heights={[290, 290, 447, 447]}
                spacer={false}
                progress={'price'}
              />
            </div>
          )}
          <div
            className={
              'flex w-full max-w-sm flex-row items-center justify-center md:max-w-full md:items-start'
            }
          >
            {formManager && <div className={formClasses}>{formManager}</div>}
          </div>
        </div>
      </section>
      {(progressToUse === 'info' || isSofi) && (
        <div
          className={`relative z-1 mt-12 w-full bg-gray-7 2xl:${
            isSofi ? '' : 'fixed'
          } 2xl:bottom-0`}
        >
          <div className="p-6 pr-16 md:mx-auto md:max-w-screen-xl lg:py-8">
            <div className="flex flex-col justify-between md:flex-row md:gap-x-2 ">
              <Caption.Regular400>
                Trustpilot rating as of 2/1/2024.
              </Caption.Regular400>
              <br />
              <Caption.Regular400>
                #1 rating is based on site visits according to Q1, 2023
                SimilarWeb Data
              </Caption.Regular400>
              <br />
              <Caption.Regular400>
                Estate Planning Tools for you and your spouse are available with
                the purchase of an eligible policy; not available in SD or WA.
              </Caption.Regular400>
            </div>
            {isSofi && (
              <div className="mt-2">
                <Markdown
                  className={styles.sofiFooter}
                  input={`*SoFi Life Insurance Agency is not connected to Ethos. All Life
                Insurance policies are provided by Ethos, the agency. SoFi Life
                Insurance Agency takes no responsibility for products, offers,
                rates, or terms offered by Ethos on this page or others. **SoFi
                Life Insurance Agency receives compensation in the event you
                obtain life insurance or another product or service through
                Ethos.** All rates, fees, offers, and terms are presented without
                guarantee and are subject to change pursuant to Ethos’
                discretion. There is no guarantee you will be approved or
                qualify for the advertised rates, fees, offers, or terms
                presented. The actual terms you may receive depend on things
                like coverage requested, medical history, and other factors.
                Terms and conditions apply. Please review important details and
                all terms and conditions before proceeding.SoFi Life Insurance
                Agency is not affiliated with Ethos and their privacy and
                security policies may differ from SoFi’s privacy and security
                policies.`}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default StraightToApp
